import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

async function showError(
  self,
  status,
  errorText = "",
  redirect,
  forbiddenErrorMessage = null
) {
  let title = self.$t("ERRORS.ERRORS.SERVER_ERROR");

  switch (status) {
    case 401:
      title = self.$t("ERRORS.ERRORS.UNAUTHORIZED");

      errorText = self.$t("ERRORS.UNAUTHORIZED_ERROR");
      break;
    case 403:
      errorText = forbiddenErrorMessage ?? self.$t("ERRORS.FORBIDDEN_ERROR");
      break;
    case 404:
      title = self.$t("ERRORS.ERRORS.NOT_FOUND");

      if (errorText.includes("id")) {
        const textArray = errorText.split(" ");
        const idTextIndex = textArray.findIndex((text) => text == "id");

        const type = textArray[1];

        let resource = type?.toUpperCase();

        resource = resource.replace("-", "_");

        const id = textArray[idTextIndex + 1];

        const link = self.$router.resolve(
          self.$objectViewRoute({ id, type })
        ).href;

        const aTag = `<a href="${link}" target="_blank">${id}</a>`;

        errorText = self.$t("ERRORS.NOT_FOUND_ERROR_WITH_ID", {
          id: aTag,
          resource: self.$te(`COMMON.THE_${resource}`)
            ? self.$t(`COMMON.THE_${resource}`)
            : self.$t("COMMON.RESOURCE_ID"),
        });
      } else {
        errorText = self.$t("ERRORS.NOT_FOUND_ERROR");
      }
      break;
    case 500:
      errorText = self.$t("ERRORS.INTERNAL_SERVER_ERROR");
      break;
    default:
      break;
  }

  await swal.fire({
    title,
    html: self.$te(`ERRORS.${errorText}`)
      ? self.$t(`ERRORS.${errorText}`)
      : errorText,
    icon: "error",
    confirmButtonText: self.$t("COMMON.OK"),
  });

  if (!redirect) return;

  if ([403, 404].includes(status)) {
    await self.$router.back();
  } else if (status == 401) {
    // await self.$router.replace({ name: "Login" });
    location.reload();
  }
}

export default {
  methods: {
    async showRequestError(
      error,
      redirect = true,
      forbiddenErrorMessage = null
    ) {
      if (error.response) {
        if (error.response.data) {
          if (error.response.data.errors) {
            for (const err of error.response.data.errors) {
              let errorText = err.detail ? err.detail : err.title;
              showError(
                this,
                parseInt(err.status),
                errorText,
                redirect,
                forbiddenErrorMessage
              );
            }
          } else {
            showError(
              this,
              parseInt(error.response.status),
              "",
              redirect,
              forbiddenErrorMessage
            );
          }
        }
      }
    },
  },
};
